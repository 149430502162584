<template>
  <p
    style="
      text-align: center;
      font-size: 50px;
      color: #0c2231;
      font-weight: bold;
    "
  >
    Your Chatbots:
  </p>
  <CRow>
    <CCol :md="3"></CCol>
  </CRow>
  <CRow>
    <CCol :md="3"></CCol>
    <CCol :md="3"></CCol>
    <CCol :md="1"></CCol>

    <CCol :md="3">
      <!-- <CButton color="dark" @click="startCreateChatbotWizard">
        <CIcon :icon="cilPlus" size="xl" />
        Create Chatbot
      </CButton> -->
    </CCol>
  </CRow>

  <CRow>
    <CCol :md="3"></CCol>
    <!-- add empty column for left spacing -->
    <CCol :md="6">
      <div class="card-wrapper">
        <!-- wrap cards in a div for centering -->
        <CRow v-if="filteredChatbots.length > 0">
          <CCard
            v-for="chatbot in filteredChatbots"
            :key="chatbot.id"
            style="margin-bottom: 10px"
          >
            <CCardHeader>
              <CCardTitle>
                Chatbot: {{ chatbot.name }}
                <CIcon
                  :icon="cilTrash"
                  size="sm"
                  class="ms-2 delete-icon"
                  @click="deleteChatbot(chatbot.id)"
                />
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              <CCardTitle>Data:</CCardTitle>
              <CCardText>
                <div style="margin-left: 20px">
                  <li v-for="line in splitText(chatbot.subject)" :key="line">
                    {{ line }}
                    <CIcon
                      :icon="cilTrash"
                      size="sm"
                      class="ms-2 delete-icon"
                      @click="deleteFile(chatbot.id, line)"
                    />
                  </li>
                </div>
              </CCardText>
              <CButton @click="launchChatbotSettings(chatbot)">
                <CIcon :icon="cilSettings" size="xl" />
                Settings
              </CButton>
              <CButton @click="startChatbot(chatbot.id)">
                <CIcon :icon="cilMediaPlay" size="xl" />
                Start Chatbot
              </CButton>

              <CButton @click="updateChatbot(chatbot)">
                <CIcon :icon="cilLibraryAdd" size="xl" />
                Add Data to Chatbot
              </CButton>

              <CButton @click="getConversationHistory(chatbot)">
                <CIcon :icon="cilHistory" size="xl" />
                Conversation History
              </CButton>
              <CButton @click="openChatbotDesign(chatbot)">
                <CIcon :icon="cilBrush" size="xl" />
                Chatbot Design
              </CButton>
            </CCardBody>
          </CCard>
          <br />
        </CRow>
        <CRow v-else>
          <CCard style="margin-bottom: 10px">
            <CCardBody align="center">
              You don't have any Chatbots set up. Let's BUILD!
              <br />
              <br />
              <CButton
                @click="startCreateChatbotWizard"
                style="font-weight: bold; color: #0c2231; font-size: 20px"
              >
                <img
                  src="../../public/images/robot.gif"
                  width="50"
                  height="50"
                />
                Create Chatbot
              </CButton>
              <br />
            </CCardBody>
          </CCard>
        </CRow>
      </div>
    </CCol>
    <CCol :md="3"></CCol>
    <!-- add empty column for right spacing -->
  </CRow>

  <CModal
    alignment="center"
    scrollable
    :visible="showSettingsModal"
    @close="closeSettingsModal"
  >
    <CModalHeader>
      <CModalTitle>{{
        editingChatbotCopy ? 'Edit Chatbot' : 'New Chatbot'
      }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <form>
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="editingChatbotCopy.name"
          />
        </div>
        <div class="form-group">
          <label for="subject">Subject</label>
          <input
            type="text"
            class="form-control"
            id="subject"
            v-model="editingChatbotCopy.subject"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="created">Created</label>
          <input
            type="datetime-local"
            class="form-control"
            id="created"
            v-model="editingChatbotCopy.created"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="chunk_style">Chunk Style:</label>
          <div class="select-wrapper">
            <select
              id="chunk_style"
              v-model="editingChatbotCopy.chunk_style"
              class="form-control"
            >
              <option value="TextSplitter">Text Splitter</option>
              <option value="JapaneseSemantic">Japanese Semantic</option>
              <option v-if="isPdfFile" value="PageChunking">
                Page Chunking
              </option>
            </select>
            <span class="dropdown-icon">
              <i class="fas fa-chevron-down"></i>
            </span>
          </div>
        </div>
        <div
          v-if="
            editingChatbotCopy.chunk_style === 'TextSplitter' ||
            editingChatbotCopy.chunk_style === 'JapaneseSemantic'
          "
        >
          <div class="form-group">
            <label for="chunk_size">Chunk Size:</label>
            <input
              type="number"
              class="form-control"
              id="chunk_size"
              v-model="editingChatbotCopy.chunk_size"
            />
          </div>
          <div class="form-group">
            <label for="token_overlap">Token Overlap:</label>
            <input
              type="number"
              class="form-control"
              id="token_overlap"
              v-model="editingChatbotCopy.token_overlap"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="max_input_token">Max input tokens:</label>
          <input
            type="number"
            class="form-control"
            id="max_input_token"
            v-model="editingChatbotCopy.max_input_tokens"
          />
        </div>
        <div class="form-group">
          <label for="temperature">Temperature:</label>
          <input
            type="number"
            class="form-control"
            id="temperature"
            v-model="editingChatbotCopy.temperature"
            :class="{ 'is-invalid': temperatureError }"
            min="0"
            max="1"
            step="any"
            required
          />
          <div v-if="temperatureError" class="invalid-feedback">
            Value must be between 0 and 1.
          </div>
        </div>
        <div class="form-group">
          <label for="model_completion">Model Completion:</label>
          <div class="select-wrapper">
            <select
              id="model_completion"
              v-model="editingChatbotCopy.model_completion"
              class="form-control"
            >
              <option value="gpt-4o">gpt-4o</option>
              <option value="gpt-4o-mini">gpt-4o-mini</option>
              <option value="claude-3-5-sonnet-20240620">
                claude-3-5-sonnet
              </option>
            </select>
            <span class="dropdown-icon">
              <i class="fas fa-chevron-down"></i>
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="tokenizer">Tokenizer:</label>
          <div class="select-wrapper">
            <select
              id="tokenizer"
              v-model="editingChatbotCopy.tokenizer"
              class="form-control"
            >
              <option value="cl100k_base">GPT-3.5 & GPT-4</option>
              <option value="o200k_base">GPT-4o & GPT-4o mini</option>
            </select>
            <span class="dropdown-icon">
              <i class="fas fa-chevron-down"></i>
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="number_context">Number Context:</label>
          <input
            type="number"
            class="form-control"
            id="number_context"
            v-model="editingChatbotCopy.number_context"
          />
        </div>
        <div class="form-group">
          <label for="prompt">Prompt</label>
          <textarea
            class="form-control"
            id="prompt"
            rows="3"
            v-model="editingChatbotCopy.prompt"
          ></textarea>
          <label for="prompt"
            >Note: clear this field if you want to use the default prompt</label
          >
        </div>
        <div class="form-group">
          <label for="characters">Characters</label>
          <input
            disabled="true"
            type="number"
            class="form-control"
            id="characters"
            v-model="editingChatbotCopy.characters"
          />
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="public"
            :checked="editingChatbotCopy.public === 1"
            @click="
              editingChatbotCopy.public =
                editingChatbotCopy.public === 1 ? 0 : 1
            "
          />
          <label class="form-check-label" for="public">Public</label>
        </div>
        <!-- <div v-if="editingChatbot.public === 1" class="form-group">
          <label for="url_snippet">URL Snippet</label>
          <input
            type="text"
            class="form-control"
            id="url_snippet"
            :value="'https://chatbotanything.com/chatbot/' + editingChatbot.id"
          />
        </div> -->
      </form>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeSettingsModal">Close</CButton>
      <CButton
        color="info"
        class="white-text"
        @click="saveChatbotSettings"
        :disabled="shouldDisableButton"
      >
        Save changes
      </CButton>
      <div class="loading-icon-wrapper">
        <template v-if="isCreatingChatbot">
          <img
            src="../../public/images/loading.gif"
            alt="Update Chatbot..."
            class="loading-icon"
          />
        </template>
      </div>
    </CModalFooter>
  </CModal>

  <div>
    <CModal
      :visible="showHistoryModal"
      @close="
        () => {
          showHistoryModal = false
        }
      "
      size="lg"
      centered
    >
      <CModalHeader>
        <CModalTitle>Conversation History</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div
          v-for="conversation in conversations"
          :key="conversation.conversation_id"
          class="card mb-3"
        >
          <div class="card-body">
            <h5 class="card-title">
              Conversation ID: {{ conversation.conversation_id }}
            </h5>
            <p class="card-text">Created: {{ conversation.created }}</p>
            <a
              href="#"
              @click.prevent="
                resumeChatbot(conversation.bot_id, conversation.conversation_id)
              "
              class="btn btn-info white-text"
              target="_blank"
              >Resume Conversation</a
            >
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="showHistoryModal = false"
          >Close</CButton
        >
      </CModalFooter>
    </CModal>
    <CModal
      :visible="showChatbotDesignModal"
      backdrop="static"
      title="Chatbot Design"
      @close="closeChatbotDesignModal"
      size="lg"
    >
      <CModalHeader>
        <CModalTitle>Chatbot Design</CModalTitle>
      </CModalHeader>

      <CRow>
        <CCol md="5" style="margin-left: 20px">
          <div>
            <div class="chatbot-input-label">Chatbot Heading:</div>
            <input
              type="text"
              class="chatbot-input"
              id="chatbotheading"
              v-model="editingChatbot.chatbotheading"
            />
          </div>

          <div>
            <div class="chatbot-input-label">Chatbot SubHeading:</div>
            <input
              type="text"
              class="chatbot-input"
              id="chatbotsubheading"
              v-model="editingChatbot.chatbotsubheading"
            />
          </div>

          <div>
            <div class="chatbot-input-label">Logo Image:</div>
            <input
              type="file"
              accept=".jpeg, .png, .jpg, .gif, .svg"
              id="image"
              @change="onFileSelect($event)"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">Header Text Color:</div>

            <input
              type="color"
              class="chatbot-input-color"
              id="headertextcolor"
              v-model="editingChatbot.headertextcolor"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">Header Background Color:</div>
            <input
              type="color"
              class="chatbot-input-color"
              id="headerbackgroundcolor"
              v-model="editingChatbot.headerbackgroundcolor"
            />
          </div>

          <div>
            <div class="chatbot-input-label">Greeting:</div>
            <input
              type="text"
              class="chatbot-input"
              id="firstmessage"
              v-model="editingChatbot.firstmessage"
            />
          </div>

          <div>
            <div class="chatbot-input-label">Assistant Name:</div>
            <input
              type="text"
              class="chatbot-input"
              id="assistantname"
              v-model="editingChatbot.assistantname"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">Chatbot Text Color:</div>
            <input
              type="color"
              class="chatbot-input-color"
              id="bottextcolor"
              v-model="editingChatbot.bottextcolor"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">Chatbot Background Color:</div>
            <input
              type="color"
              class="chatbot-input-color"
              id="botbackgroundcolor"
              v-model="editingChatbot.botbackgroundcolor"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">User Text Color:</div>
            <input
              type="color"
              class="chatbot-input-color"
              id="usertextcolor"
              v-model="editingChatbot.usertextcolor"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">User Background Color:</div>
            <input
              type="color"
              class="chatbot-input-color"
              id="userbackgroundcolor"
              v-model="editingChatbot.userbackgroundcolor"
            />
          </div>

          <div>
            <div class="chatbot-input-label">Input Placeholder Text:</div>
            <input
              type="text"
              class="chatbot-input"
              id="inputplaceholdertext"
              v-model="editingChatbot.inputplaceholdertext"
            />
          </div>

          <div class="d-flex justify-content-between chatbot-setting-row">
            <div class="chatbot-input-label">Send Button Background Color:</div>
            <input
              type="color"
              class="chatbot-input-color"
              id="sendbuttonbackgroundcolor"
              v-model="editingChatbot.sendbuttonbackgroundcolor"
            />
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin-bottom: 20px"
          >
            <CButton color="secondary" @click="closeChatbotDesignModal">
              Close
            </CButton>
            <CButton color="info" class="white-text" @click="saveChatbotDesign"
              >Save</CButton
            >
          </div>
        </CCol>
        <CCol :md="6" class="d-flex align-items-center justify-content-center">
          <component
            :is="activeComponent"
            bot_id="preview"
            :style="getEditingStyle(editingChatbot)"
            :key="editingChatbot.firstmessage"
          ></component>
        </CCol>
      </CRow>
      <CRow> </CRow>
    </CModal>
  </div>
  <ProcessingOverlay :show="isProcessing" />
</template>
<script>
import HTTPService from '@/services/HTTPService.js'
import { CCardText } from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import ProcessingOverlay from '@/components/ProcessingOverlay.vue'
import {
  cilMediaPlay,
  cilSettings,
  cilPlus,
  cilLibraryAdd,
  cilHistory,
  cilBrush,
  cilTrash,
} from '@coreui/icons'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/vue'

import ChatbotAnything from './Chatbot.vue'

// import * as icon from '@coreui/icons'
export default {
  data() {
    return {
      chatbots: [],
      item: { color: 'dark', textColor: 'white' },
      cilMediaPlay,
      cilSettings,
      cilPlus,
      cilLibraryAdd,
      cilHistory,
      cilBrush,
      cilTrash,
      showSettingsModal: false,
      editingChatbot: {},
      showHistoryModal: false,
      conversations: [],
      showChatbotDesignModal: false,
      activeComponent: ChatbotAnything,
      isProcessing: false,
      editingChatbotCopy: {},
      isCreatingChatbot: false,
      temperatureError: false,
    }
  },
  watch: {
    'editingChatbotCopy.temperature': function (newValue) {
      if (newValue < 0 || newValue > 1 || isNaN(newValue)) {
        this.temperatureError = true
      } else {
        this.temperatureError = false
      }
    },
  },
  mounted() {
    this.getChatbots()
  },
  computed: {
    filteredChatbots() {
      return this.chatbots.filter(
        (chatbot) => !chatbot.name.startsWith('[POC] '),
      )
    },
    isPdfFile() {
      return (
        this.editingChatbot.subject &&
        this.editingChatbot.subject
          .split('||')
          .some((file) => file.trim().endsWith('.pdf'))
      )
    },
    shouldDisableButton() {
      return this.isCreatingChatbot
    },
  },
  methods: {
    onFileSelect(event) {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (e) => {
        const img = new Image()

        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          canvas.width = 40
          canvas.height = 40

          // Draw the image onto the canvas with the desired dimensions
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

          // Get the resized image as a base64 encoded string
          const resizedBase64Data = canvas.toDataURL('image/png').split(',')[1]

          // Update the editingChatbot image property
          this.editingChatbot.image =
            'data:image/png;base64,' + resizedBase64Data
        }

        // Set the image source to the loaded data
        img.src = e.target.result
      }

      if (file) {
        reader.readAsDataURL(file)
      } else {
        this.editingChatbot.image = null // Set .image to null if no file is selected
      }
    },
    getEditingStyle(editingChatbot) {
      // console.log('this is getEditingStyle on loading ')
      // console.log(editingChatbot)
      // be carefull not to use chatbot.style for saving/binding values
      return {
        chatbotHeading: editingChatbot.chatbotheading,
        chatbotSubHeading: editingChatbot.chatbotsubheading,
        firstMessage: editingChatbot.firstmessage,
        headerBackgroundColor: editingChatbot.headerbackgroundcolor,
        headerTextColor: editingChatbot.headertextcolor,
        image: editingChatbot.image,
        inputPlaceholderText: editingChatbot.inputplaceholdertext,
        sendButtonBackgroundColor: editingChatbot.sendbuttonbackgroundcolor,
        userBackgroundColor: editingChatbot.userbackgroundcolor,
        userTextColor: editingChatbot.usertextcolor,
        botBackgroundColor: editingChatbot.botbackgroundcolor,
        botTextColor: editingChatbot.bottextcolor,
        assistantName: editingChatbot.assistantname,
      }
    },
    updateChatbot(chatbot) {
      this.$emit('change-active-component', {
        component: 'ChatbotStep1',
        params: {
          bot_id: chatbot.id,
          chatbot_name: chatbot.name,
          chunk_style: chatbot.chunk_style,
          chunk_size: chatbot.chunk_size,
          token_overlap: chatbot.token_overlap,
          max_input_tokens: chatbot.max_input_tokens,
          tokenizer: chatbot.tokenizer,
        },
      })
    },
    async getChatbots() {
      try {
        const chatbots = await HTTPService.get_chatbots()
        // console.log(chatbots.data)
        this.chatbots = chatbots.data
      } catch (error) {
        console.log(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      }
    },
    startCreateChatbotWizard() {
      this.$emit('change-active-component', {
        component: 'ChatbotStep1',
      })
    },
    launchChatbot(chatbot) {
      // launch selected chatbot
      // eslint-disable-next-line no-unused-vars
      chatbot = null
    },
    launchChatbotSettings(chatbot) {
      //console.log(chatbot)
      //console.log('chatbot.public')
      //console.log(chatbot.public === 1)

      this.editingChatbot = chatbot
      this.showSettingsModal = true
      // console.log(this.editingChatbot)
      this.editingChatbotCopy = JSON.parse(JSON.stringify(chatbot))
    },
    openChatbotDesign(chatbot) {
      // This object should contain the existing chatbot properties
      this.editingChatbot = chatbot
      // console.log(this.editingChatbot)
      // Show the chatbot design modal
      this.showChatbotDesignModal = true
    },
    closeChatbotDesignModal() {
      // Close the chatbot design modal
      this.showChatbotDesignModal = false
    },
    async saveChatbotDesign() {
      try {
        const response = await HTTPService.update_chatbot(this.editingChatbot)
        console.log(response.data)
        this.getChatbots()
        this.showChatbotDesignModal = false
      } catch (error) {
        console.log(error)
      }
    },
    showHistory(chatbot) {
      //console.log(chatbot)
      //console.log('chatbot.public')
      //console.log(chatbot.public === 1)

      this.editingChatbot = chatbot
      this.showSettingsModal = true
      //console.log(this.showSettingsModal)
    },
    async saveChatbotSettings() {
      try {
        this.editingChatbot = { ...this.editingChatbotCopy }
        this.isCreatingChatbot = true
        const response = await HTTPService.update_chatbot(this.editingChatbot)
        console.log(response.data)
        this.showSettingsModal = false
        this.getChatbots()
      } catch (error) {
        console.error('Failed to save chatbot settings:', error)
      } finally {
        this.isCreatingChatbot = false
      }
    },
    startChatbot(bot_id) {
      // const name = 'My Bot' // replace with actual bot name
      // this.$router.push({ name: 'Chatbot', params: { bot_id } })
      this.$emit('change-active-component', {
        component: 'Chatbot',
        params: { bot_id: bot_id },
      })
    },
    resumeChatbot(bot_id, conversationId) {
      this.showHistoryModal = true
      // console.log(bot_id + ' conv:' + conversationId)
      // this.$emit('change-active-component', {
      //   component: 'Chatbot',
      //   params: { bot_id: bot_id },
      // })
      this.$emit('change-active-component', {
        component: 'Chatbot',
        params: { bot_id: bot_id, conversation_id: conversationId },
      })
    },

    // resumeChatbot(bot_id, conversationId) {
    //   // Open chatbot in a new window/tab
    //   const url = `${window.location.origin}/chatbot/${bot_id}?conversationId=${conversationId}`
    //   window.open(url, '_blank')
    //   this.$emit('change-active-component', {
    //     component: 'Chatbot',
    //     params: { bot_id: bot_id },
    //   })
    //   // Hide conversation history modal
    //   this.showHistoryModal = false
    // },
    defaultUrlSnippet(editingChatbot) {
      return 'https://chatbotanything.com/chatbot/' + editingChatbot.bot_id
    },
    async getConversationHistory(chatbot) {
      try {
        console.log(chatbot.id)
        const response = await HTTPService.get_conversation_history(chatbot.id)
        //console.log(response)
        this.conversations = response.data
        this.showHistoryModal = true
      } catch (error) {
        console.error(error)
      }
    },
    splitText(text) {
      const lines = text.split('||')
      let items = []
      for (let i = 0; i < lines.length; i++) {
        const parts = lines[i].split(';')
        for (let j = 0; j < parts.length; j++) {
          if (parts[j]) {
            items.push(parts[j])
          }
        }
      }
      return items
    },
    async deleteFile(bot_id, filename) {
      try {
        const data = {
          bot_id: bot_id,
          filename: filename,
        }
        this.isProcessing = true
        await HTTPService.delete_file(data)
        await this.getChatbots()
      } catch (error) {
        console.log(error)
      } finally {
        this.isProcessing = false
      }
    },
    async deleteChatbot(bot_id) {
      try {
        const data = {
          bot_id: bot_id,
        }
        this.isProcessing = true
        await HTTPService.delete_chatbot(data)
        await this.getChatbots()
      } catch (error) {
        console.log(error)
      } finally {
        this.isProcessing = false
      }
    },
    closeSettingsModal() {
      this.showSettingsModal = false
    },
  },
  components: {
    CCardText,
    CIcon,
    CModal,
    CModalTitle,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    ChatbotAnything,
    ProcessingOverlay,
  },
}
</script>
<style scoped>
.chatbot-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatbot-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.chatbot-list {
  flex: 1;
  padding: 10px;
}

h2 {
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  padding: 5px;
  background-color: #ccc;
}

td {
  padding: 5px;
}

tr:hover {
  cursor: pointer;
  background-color: #eee;
}

.settings-btn {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #eee;
  color: #333;
}

.start-chatbot-btn {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #00a0df;
  color: #fff;
}

.start-chatbot-btn:hover {
  cursor: pointer;
  background-color: #0089b9;
}

/* Ensure that the CModal component is displayed above other elements */
.modal-content {
  z-index: 9999;
}

.chatbot-input-label {
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 700;
}

.chatbot-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  line-height: 1.5rem;
  outline: none;
}

.chatbot-input-color {
  width: 25%;
  padding: 0px;
  height: 30px;
  /* border: none; */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 0 1px white, 0 0 0 2px black;
  cursor: pointer;
  outline: none;
}
.chatbot-setting-row {
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
input {
  outline: none;
}
.delete-icon {
  cursor: pointer;
}

.delete-icon:hover {
  outline: 1px solid #000;
  border-radius: 5px;
}

.form-group {
  position: relative;
}

.select-wrapper {
  position: relative;
}

.form-control {
  padding-right: 40px;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #333;
  pointer-events: none;
}

.loading-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
  background-color: transparent;
}

.loading-icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
</style>
